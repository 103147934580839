import { z } from 'zod'
import type { MutationObserverOptions } from '@tanstack/vue-query'
import type { MaybeRefDeep } from '@tanstack/vue-query/build/modern/types'
import { zodTranslation } from '~/utils'
import type { CreateProcessingAidGroupRequest, ProcessingAidGroupResponse } from '~/clients'
const schema = z.object({
  nameAsJson: zodTranslation('Description is required.'),
  descriptionAsJson: zodTranslation('Description is required.'),
})

export const useManageProcessingAidGroups = (
  type: 'create' | 'update',
  defaults?: MaybeRefDeep<CreateProcessingAidGroupRequest>,
  id?: number,
  mutationOptions?: MaybeRefDeep<MutationObserverOptions>,
) => {
  return useManageApi<ProcessingAidGroupResponse, CreateProcessingAidGroupRequest>(
    type,
    id,
    'processing-aid-groups',
    defaults,
    'Processing Aid Group',
    mutationOptions,
    schema,
  )
}
