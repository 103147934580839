import type { DehydratedState, VueQueryPluginOptions } from '@tanstack/vue-query'
import { VueQueryPlugin, QueryClient, hydrate, dehydrate, QueryCache } from '@tanstack/vue-query'
// Nuxt 3 app aliases
import { defineNuxtPlugin, useState } from '#imports'
// import { toast } from 'vue3-toastify'
import { ZodError } from 'zod'
import { FetchError } from 'ofetch'

export default defineNuxtPlugin((nuxt) => {
  const vueQueryState = useState<DehydratedState | null>('vue-query')
  const t = nuxt.$i18n.t
  const toast = useToast()
  const route = useRoute()
  // Modify your Vue Query global settings here
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // staleTime: 60000,
        refetchOnWindowFocus: false,
        retry(failureCount, error) {
          return failureCount < 0
        },
        throwOnError: (error) => {
          console.log('Query error', error)
          throw createError(error)
        },
      },
      mutations: {
        throwOnError: (error) => {
          console.log('Mutation error')
          throw createError(error)
        },
      },
    },
    queryCache: new QueryCache({}),
  })
  const options: VueQueryPluginOptions = { queryClient }

  nuxt.vueApp.use(VueQueryPlugin, options)

  if (process.server) {
    nuxt.hooks.hook('app:rendered', () => {
      vueQueryState.value = dehydrate(queryClient)
    })
  }

  if (process.client) {
    nuxt.hooks.hook('app:created', () => {
      hydrate(queryClient, vueQueryState.value)
    })
  }
})
