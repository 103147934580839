import type { SampleResponse } from '~/clients'

const useGetPortalSample = (id: string, type: 'samples' | 'planned-samples' | 'copied-samples') => {
  const query = useQuery({
    queryKey: [type, id],
    queryFn: async (context) => {
      return await useApiClient<SampleResponse>(`/portal/${type}/${id}`, {
        method: 'GET',
        signal: context.signal,
      })
    },
  })

  const tracingDataNotEmpty = computed(
    () =>
      !!query.data.value?.countryId ||
      !!query.data.value?.batchNumber ||
      !!query.data.value?.producerLocationId ||
      !!query.data.value?.supplierLocationId ||
      !!query.data.value?.characteristics?.length,
  )

  const analysisReportNotEmpty = computed(() => query.data.value?.analysisReports?.length)

  const combinedAnalysisAssignmentsNotEmpty = computed(
    () => query.data.value?.combinedAnalysisAssignments?.length,
  )

  return {
    ...query,
    tracingDataNotEmpty,
    analysisReportNotEmpty,
    combinedAnalysisAssignmentsNotEmpty,
  }
}

const getSampleById = (id: string) =>
  useApiClient<SampleResponse>(`/portal/samples/${id}`, {
    method: 'GET',
  })

export { useGetPortalSample, getSampleById }
