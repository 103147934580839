import { AuthRole } from '~/utils/auth'

export const HubRoutes = [
  {
    name: 'dashboard',
    path: '/hub/dashboard',
    icon: 'dashboard',
    auth: {
      roles: [AuthRole.QRAY_GMP],
    },
  },
  {
    name: 'management',
    path: '/hub/management',
    icon: 'management',
    auth: {
      roles: [AuthRole.QRAY_GMP],
    },
    children: [
      { name: 'products', path: '/hub/management/products' },
      { name: 'hazards', path: '/hub/management/hazards' },
      { name: 'controlMeasures', path: '/hub/management/control-measures' },
      { name: 'processingAids', path: '/hub/management/processing-aids' },
      { name: 'processSteps', path: '/hub/management/process-steps' },
      {
        name: 'productGroups',
        path: '/hub/management/product-groups',
      },
      {
        name: 'controlMeasureGroups',
        path: '/hub/management/control-measure-groups',
      },
      {
        name: 'processingAidGroups',
        path: '/hub/management/processing-aid-groups',
      },
      {
        name: 'processStepGroups',
        path: '/hub/management/process-step-groups',
      },
      {
        name: 'monitoringGroups',
        path: '/hub/management/monitoring-groups',
      },
      { name: 'methods', path: '/hub/management/methods' },
      { name: 'units', path: '/hub/management/units' },
    ],
  },
  {
    name: 'riskAssessments',
    path: '/hub/risk-assessments',
    icon: 'risk-assessments',
    auth: {
      roles: [AuthRole.QRAY_GMP],
    },
  },
  {
    name: 'monitoring',
    path: '/hub/monitoring',
    icon: 'ic:outline-traffic',
    auth: {
      roles: [AuthRole.QRAY_GMP],
    },
  },
  {
    name: 'incomingEmails',
    path: '/hub/incoming-emails',
    icon: 'ic:baseline-mail-outline',
    auth: {
      roles: [AuthRole.QRAY_GMP],
    },
  },
  // {
  //   name: "Company database",
  //   path: "/hub/management/company-database",
  //   icon: "companies",
  //   children: [
  //     {
  //       name: "My groups",
  //       children: [
  //         {
  //           name: "My group 1",
  //           path: "/hub/management/company-database/1",
  //         },
  //       ],
  //     },
  //   ],
  // },
]

export const PortalRoutes = (t: (str: string) => string) => [
  { name: 'dashboard', path: '/portal/dashboard', icon: 'dashboard' },
  {
    id: 'menu-feed-scheme-documents',
    name: 'feedSchemeDocuments',
    path: '/portal/feed-scheme-documents',
    icon: 'grain',
    // children: [
    //   {
    //     name: 'framework',
    //     path: '/portal/gmp-feed-scheme-documents/framework',
    //     disabled: true,
    //   },
    //   {
    //     name: 'requirements',
    //     path: '/portal/gmp-feed-scheme-documents/requirements',
    //     disabled: true,
    //   },
    //   {
    //     name: 'support',
    //     path: '/portal/gmp-feed-scheme-documents/support',
    //     disabled: true,
    //   },
    // ],
  },
  {
    id: 'menu-risk-management',
    name: 'riskManagementTools',
    path: '/portal/risk-management',
    icon: 'traffic',
    auth: {
      roles: [AuthRole.QRAY_FSP, AuthRole.QRAY_MON, AuthRole.QRAY_GMP],
    },
    children: [
      {
        name: 'riskAssessments',
        path: '/portal/risk-management/risk-assessments',
      },
      {
        name: 'acceptedFeedMaterials',
        path: '/portal/risk-management/accepted-feed-materials',
      },
      {
        name: 'processingAids',
        path: '/portal/risk-management/processing-aids',
      },
      {
        name: 'undesirableSubstances',
        path: '/portal/risk-management/undesirable-substances',
      },
      {
        name: 'monitoringDatabase',
        path: '/portal/risk-management/monitoring-database',
        auth: {
          roles: [AuthRole.QRAY_GMP, AuthRole.QRAY_MON],
        },
        children: [
          {
            name: 'myMonitoring',
            path: '/portal/risk-management/monitoring-database/my-monitoring',
            auth: {
              roles: [AuthRole.QRAY_GMP, AuthRole.QRAY_MON],
            },
          },
          {
            name: 'analysisSets',
            path: '/portal/risk-management/monitoring-database/analysis-sets',
            disabled: false,
            auth: {
              roles: [AuthRole.QRAY_GMP],
            },
          },
          {
            name: 'myGroups',
            path: '/portal/risk-management/monitoring-database/my-groups',
            disabled: false,
            auth: {
              roles: [AuthRole.QRAY_MON],
            },
          },
          {
            name: 'groupManagement',
            path: '/portal/risk-management/monitoring-database/management-group-members',
            disabled: false,
            auth: {
              roles: [AuthRole.QRAY_GMP],
            },
          },
          {
            name: 'reports',
            path: '/portal/risk-management/monitoring-database/reports',
            disabled: false,
            auth: {
              roles: [AuthRole.QRAY_GMP],
            },
          },
          {
            name: 'codeLists',
            path: '/portal/risk-management/monitoring-database/code-lists',
          },
        ],
      },
    ],
  },
  {
    name: 'gmpCompanyDatabase',
    icon: 'companies',
    path: '/portal/gmp-company-database',
    children: [
      {
        name: 'certifiedCompanies',
        path: t('externalLinks.companyDatabase.certifiedCompanies'),
        target: '_blank',
      },
      {
        name: 'certificationBodies',
        path: t('externalLinks.companyDatabase.certificationBodies'),
        target: '_blank',
      },
      {
        name: 'laboratories',
        path: t('externalLinks.companyDatabase.laboratories'),
        target: '_blank',
      },
      {
        name: 'partners',
        path: t('externalLinks.companyDatabase.partners'),
        target: '_blank',
      },
      {
        name: 'otherCertificationSchemes',
        path: t('externalLinks.companyDatabase.otherCertificationSchemes'),
        target: '_blank',
      },
    ],
  },
  {
    name: 'earlyWarningSystem',
    path: t('externalLinks.earlyWarningSystem'),
    icon: 'ews',
    target: '_blank',
  },
  {
    name: 'cbLogin',
    path: t('externalLinks.cbLogin'),
    icon: 'ic:baseline-card-travel',
    target: '_blank',
    auth: {
      roles: [AuthRole.PORTAL_TCFSP, AuthRole.PORTAL_COORDINATOR],
    },
  },
]
